<div class="side-panel" id="sidePanel" [ngClass]="openClass"
	[ngStyle]="{'width' : (sidePanelWidthOpen.measurement === 'px' ? sidePanelWidthOpen + 'px' : '100%')}">
	<div class="side-panel__wrap d-flex align-items-stretch h-100">
		<div class="side-panel__content flex-fill">
			<ng-content></ng-content>
		</div>
		<div class="side-panel__toggle"
			[ngStyle]="{'min-width' : sidePanelWidthClosed + 'px', 'max-width' : sidePanelWidthClosed + 'px'}">
			<button class="side-panel__toggle-button btn btn-icon p-0" [attr.aria-expanded]="!isCollapsed"
				(click)="togglePanel(!isCollapsed)" title="Toggle Side Panel" aria-controls="sidePanel">
				<i class="far fa-chevron-right toggle-chevron"></i>
			</button>
			<button class="side-panel__edit-button btn btn-link p-0 bg-white" title="Edit Mode">
				<i class="far fa-edit"></i>
			</button>
		</div>
	</div>
</div>