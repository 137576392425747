import { NgClass, NgStyle } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';


export interface ISidePanelWidth {
	width: number;
	measurement: string;
}
@Component({
    selector: "rl-collapsing-side-panel",
    templateUrl: "./collapsing-side-panel.component.html",
    styleUrls: ["./collapsing-side-panel.component.scss"],
    imports: [NgClass, NgStyle]
})
export class CollapsingSidePanelComponent implements OnInit {

	@Input()
	isCollapsed: boolean = false;

	@Input()
	openTo: string;

	@Output() sidePanelWidth = new EventEmitter<number>();

	@Input()
	sidePanelWidthOpen: ISidePanelWidth;

	@Input()
	sidePanelWidthClosed: number;

	private _openClass: string = '';

	@Input()
	public set openClass(className: string) {
		this._openClass = className;
	}

	public get openClass(): string {
		return this._openClass;
	}

	constructor() { }

	ngOnInit(): void {
		this.openClass = this.isCollapsed ? 'closed' : 'open';
	}

	togglePanel(collapse: boolean) {
		this.isCollapsed = collapse ? true : false;
		this.sidePanelWidth.emit(collapse ? this.sidePanelWidthClosed : this.sidePanelWidthOpen.width);
		this.openClass = collapse ? 'closed' : 'open';
	}
}
